<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-card-title>Rediģēt reklāmu</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field label="Nosaukums" v-model="form.name" outlined autofocus required :rules="rules"/>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Budžets" v-model="form.budget" outlined required :rules="rules"/>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Ad set sk." v-model="form.ad_set" outlined/>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Type" v-model="form.type" outlined required :rules="rules"/>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-switch v-model="form.target_type" value="L" label="Lead"></v-switch>
              <v-switch v-model="form.target_type" value="T" label="Target"></v-switch>
              <v-switch v-model="form.target_type" value="R" label="Reach"></v-switch>
              <v-switch v-model="form.target_type" value="LL" label="Lookalike"></v-switch>
              <v-switch v-model="form.target_type" value="PO" label="Post"></v-switch>
              <v-switch v-model="form.target_type" value="P" label="Product set"></v-switch>
              <v-switch v-model="form.target_type" value="RM" label="Remarketing"></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="form.date_start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_start"
                    label="Sākuma datums"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_start" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(form.date_start)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="form.date_end"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_end"
                    label="Datuma beigas"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_end" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(form.date_end)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Apraksts" v-model="form.description" outlined/>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="form.status"
                :items="statuses"
                label="Statuss"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <AlertResponse :response="alert"/>
      <v-card-actions>
        <v-btn :disabled="!valid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'AdvertisementEdit',
  components: {
    AlertResponse
  },
  data: () => ({
    advertisementId: null,
    loading: true,
    valid: false,
    rules: [
      value => !!value || 'Nepieciešams'
    ],
    alert: null,
    modal: false,
    modal2: false,
    form: {
      company_id: null,
      name: '',
      ad_account: '',
      date_start: '',
      date_end: '',
      type: '',
      target_type: [],
      budget_date: '',
      budget: '',
      ad_set: '',
      description: '',
      sort_order: 100,
      status: 1
    }
  }),
  computed: {
    statuses () {
      return this.$store.state.statuses
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.advertisementId = this.$route.params.id
      axios.get('advertisements/' + this.advertisementId).then(response => {
        this.form = response.data
        this.loading = false
      }).catch(error => {
        alert(error)
      })
    },
    async save () {
      if (this.valid === true) {
        await axios.put('advertisements/' + this.advertisementId, this.form).then(response => {
          this.alert = response.data
          this.valid = false
          setTimeout(() => this.$router.push({ name: 'advertisementIndex' }), 2000)
        }).catch(error => {
          this.alert = error.data
          this.valid = true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
